import { SdkConfig } from '@criptalia/sdk/src/Sdk'

const wsProtocol = window.location.protocol === 'https:' ? 'wss://' : 'ws://'

const websocketUrl = wsProtocol + window.location.host + '/connection/websocket'

const isProduction = true // TODO: check if correct
export const FRONTEND_ENV: SdkConfig['env'] = {
  isProduction,
  isProductionBuild: import.meta.env.PROD,
  websocketUrl,
  brandName: 'FIDIT LaaS',
  datocmsToken:
    import.meta.env.VITE_DATO_CMS_TOKEN || 'f3d446d57f33bc90ac0d65aa7aca1b',
  mapsApiKey:
    import.meta.env.VITE_MAPS_API_KEY ||
    'AIzaSyB15ds5CBvNKu8VGdxMxKp7nVPPLYtqQkM',
}
