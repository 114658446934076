//do not import things that require SDK files directly here or Sdk overrides will not work
import React from 'react'
import { createRoot, Root } from 'react-dom/client'
import '@fortawesome/fontawesome-free/css/all.css'
import '@style/main.scss'
import 'moment/dist/locale/it'
import './index.css'
import {
  getBestLang,
  setCurrentLang,
} from '@criptalia/sdk/src/utils/translatorUtils'
import externalRedirect from '@components/ExternalRedirect'
import parseReferralCookies from '@components/ReferralCookie'
import { Sdk, SdkConfig } from '@criptalia/sdk/src/Sdk'
// @ts-ignore
import moment from 'moment'
import { FRONTEND_ENV } from '@src/env'

const App = React.lazy(() => import('@src/App'))

const lang = 'it'
moment.locale(lang)
setCurrentLang(lang,'')

//required for criptalia-sdk to work - override here for SaaS
Sdk.init({
  env: FRONTEND_ENV,
  variables: {
    lang: {
      dev: ['it'],
      prod: ['it'],
    },
  },
  api: {
    baseUrl: import.meta.env.PROD ? import.meta.env.VITE_API_URL : '/',
  },  
} as Partial<SdkConfig>).then(() => {
    const container = document.getElementById('root')
    const root: Root = createRoot(container)
    root.render(<App />)
})

/*
When React renders, some events happen that may even redirect the user to an
external website. This means that, before even starting react, we need to check
cookies and language.
*/
function checkRedirects(): boolean {
  let redirectUrl: string = ''

  const refUrl: string = parseReferralCookies(redirectUrl)
  if (refUrl) {
    redirectUrl = refUrl
  }

  const langUrl = setCurrentLang(lang, redirectUrl)
  if (langUrl) {
    redirectUrl = langUrl
  }

  const externalUrl = externalRedirect(lang, redirectUrl)
  if (externalUrl) {
    redirectUrl = externalUrl
  }

  if (redirectUrl === window.location.href) {
    window.location.reload()
  } else {
    window.location.replace(redirectUrl)
  }
  
  return true
}
